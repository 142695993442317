<script setup lang="ts">
const { name, animation } = defineProps<{
  name: string;
  animation?: "spin";
}>();

const classObject = computed(() => ({
  [`has-animation-${animation}`]: animation,
}));
</script>

<template>
  <span class="base-icon material-symbols" :class="classObject">
    {{ name }}
  </span>
</template>

<style lang="scss" scoped>
.base-icon {
  &.has-animation-spin {
    animation: spin 1500ms linear infinite;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
</style>
